import { LIST_CERTIFICATE,CREATE_CERTIFICATE ,VIEW_CERTIFICATE,EDIT_CERTIFICATE,DELETE_CERTIFICATE} from "../types";

const initialState = {
  list: [],
  listisLoaded: false,
  count: 0,
  certificates: {},
  isLoaded: false,
};


export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_CERTIFICATE:
      
      return {
        ...state,
        list: action.payload.certificates,
        totalCount: action.payload.count,
        isLoaded: true,
      };
     
      case VIEW_CERTIFICATE:
        return {
          ...state,
          certificates: action.payload,
        };
      case CREATE_CERTIFICATE:
        return {
          ...state,
          isLoaded: false,
        };
        case EDIT_CERTIFICATE:
          console.log("dfd44555",action.payload)
          return {
            ...state,
            certificates: {},
            isLoaded: false,
            allCertificatesIsLoaded: false,
          };
          case DELETE_CERTIFICATE:
        return {
          ...state,
          isLoaded: false,
          allCertificatesIsLoaded: false,
        };
        default:
          return state; 
  }
};



