import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
} from "./utils/localStorage";
import store from "./store.js";
import { LOGOUT } from "./redux/types";
import CONF from "./config.json";
import { refreshToken } from "./redux/actions/authActions";

const axiosAdapter = axios.create({
  baseURL: CONF.BASE_URL,
});

axiosAdapter.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosAdapter.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error && error.response) {
      const { status } = error.response;
      if (status === 401) {
        const refreshTokens = getRefreshToken();
        if (refreshTokens) {
          const res = await store.dispatch(
            refreshToken({ refreshToken: `${JSON.parse(refreshTokens)}` })
          );
          if (res) {
            const { config: oldRequest } = error;
            const response = axios.request({
              ...oldRequest,
              headers: {
                ...oldRequest.headers,
                Authorization: `Bearer ${JSON.parse(getAccessToken())}`,
              },
            });
            return response;
          }
        }
        store.dispatch({ type: LOGOUT });
        removeTokens();
      }
    }
    return Promise.reject(error);
  }
);

export default axiosAdapter;
