import {
  LIST_INHAND,
  LIST_OUTBOUND,
  LIST_INBOUND,
  LIST_RECEIVED,
  LIST_SENT,
  VIEW_INBOUND,
  RECEIVE_DOCUMENT,
  VIEW_INHAND,
  VIEW_OUTBOUND,
  VIEW_SENT,
  VIEW_RECEIVED,
} from "../types";

const initialState = {
  inHandList: [],
  outBoundList: [],
  inBoundList: [],
  receivedList: [],
  sentList: [],
  inboundView: [],
  inhandView:[],
  processes:[],
  outboundView:[],
  documents: [],
  receivedView:[],
  sentView:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_INHAND:
      return {
        ...state,
        inHandList: action.payload.inHandList,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case LIST_OUTBOUND:
      return {
        ...state,
        outBoundList: action.payload.outBoundList,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case LIST_INBOUND:
      return {
        ...state,
        inBoundList: action.payload.inBoundList,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case LIST_RECEIVED:
      return {
        ...state,
        receivedList: action.payload.receivedList,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case LIST_SENT:
      return {
        ...state,
        sentList: action.payload.sendList,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case VIEW_INBOUND:
      return {
        ...state,
        inboundView: action.payload.transits,
        documents: action.payload.documents,
      };
    case VIEW_INHAND:
      return {
        ...state,
        inhandView: action.payload.services,
        processes: action.payload.processes
      };
    case VIEW_OUTBOUND:
      return {
        ...state,
        outboundView: action.payload,
      };
    case VIEW_RECEIVED:
      return {
        ...state,
        receivedView: action.payload,
      };
    case VIEW_SENT:
      return {
        ...state,
        sentView: action.payload,
      };
    case RECEIVE_DOCUMENT:
      return {
        ...state,
        documents: {},
        isLoaded: false,
        alldocumentsIsLoaded: false,
      };
    default:
      return state;
  }
}
