import { CREATE_AGENCY, VIEW_AGENCY, LIST_AGENCY,EDIT_AGENCY } from "../types";
const initialState = {
  isLoaded: false,
  agency: {},
  agencyList: [],
  totalCount: 0,
  agencyProcess:[]

};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_AGENCY:
      return {
        ...state,
        agencyList: action.payload.agencies,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case VIEW_AGENCY:
      return {
        ...state,
        agency: action.payload.agencies,
        agencyProcess:action.payload.subAndCourirerAgencies,

      };
    case CREATE_AGENCY:
      return {
        ...state,
        isLoaded: false,
      };
      case EDIT_AGENCY:
        return {
          ...state,
          agency: {},
          isLoaded: false,
          allemployeeLoaded: false,
        };

    default:
      return state;
  }
}
