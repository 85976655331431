import {
    HEADER_INORMATIVE_POPUP_OPEN_TOGGLE
  } from "../types";

// Initial State
const initialState = {
    inormativePoupOpen: false,
    title: "",
    description: "",
    redirectUrl: "",
  };
  
  // Reducer
  const headerReducer = (state = initialState, action) => {
    switch (action.type) {
      case HEADER_INORMATIVE_POPUP_OPEN_TOGGLE:
        return {
          ...state,
          inormativePoupOpen: action.payload.open,
          title: action.payload.title,
          description: action.payload.description,
          redirectUrl: action.payload.redirectUrl,
        };
      default:
        return state;
    }
  };
  
  export default headerReducer;
  