import { DETAILEDVIEW_DASHBOARD, VIEW_DASHBOARD } from "../types";

const initialState={
 data:[],
 detaileddata:[]
};

export default function (state = initialState, action) {
    switch (action.type) {
        case VIEW_DASHBOARD:
            return {
                ...state,
                data:action.payload.dashboard,
            }
        case DETAILEDVIEW_DASHBOARD:
            return {
                ...state,
                detaileddata:action.payload,
            }
        
        default:
          return state;
    }
    
}