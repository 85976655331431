import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "./components/Loader";
// import ResetPasswordPage from './pages/ResetPassword'
import PrivateRoute from "./layouts/PrivateRoute";
import AuthRoute from "./layouts/AuthRoute";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";

// Containers
const DefaultLayout = React.lazy(() => import("./layouts/DefaultLayout"));

// Pages
const LoginPage = React.lazy(() => import("./pages/Login/"));
const ForgotPasswordPage = React.lazy(() => import("./pages/ForgotPassword/"));
const ResetPasswordPage = React.lazy(() => import("./pages/ResetPassword/"));

function App() {
  return (
    //The below used style classs are imported from _utilities.scss, which is under @coreui file in node-modules
    <div className='bg-light min-vh-100 d-flex flex-row align-items-center'>
      <ToastContainer theme="dark" autoClose={500}/>
      {/* <NotificationContainer /> */}
      <Suspense fallback={Loader()}>
        <Routes>
          <Route
            exact
            path='/login'
            name='Login Page'
            element={
              <AuthRoute>
                <LoginPage />
              </AuthRoute>
            }
          />
          <Route
            exact
            path='/forgot-password'
            name='Forgot Password Page'
            element={
              <AuthRoute>
                <ForgotPasswordPage />
              </AuthRoute>
            }
          />
          <Route
            exact
            path='/reset-password/:token'
            name='Reset Password Page'
            element={
              <AuthRoute>
                <ResetPasswordPage />
              </AuthRoute>
            }
          />

          <Route
            path='*'
            name='Home'
            element={
              <PrivateRoute>
                <DefaultLayout />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
