import { LOGIN, RESET_PASSWORD, CHANGE_PASSWORD,LOGOUT,PROFILE,
  TOGGLE_LOGOUT_POPUP
} from "../types";
import { getUser, isAuthenticated } from "../../utils/localStorage";

const initialState = {
  user: getUser() ? JSON.parse(getUser()) : null,
  isLoggedIn: isAuthenticated(),
  userProfile: null,

};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };
      case RESET_PASSWORD:
      return {
        ...state,
        user: action.payload,
        isLoaded: false,
      };
      case CHANGE_PASSWORD:
      return {
        ...state,
        user: action.payload,
        isLoaded: false,
      };
      case LOGOUT:
        return {
          ...state,
          user: null,
          isLoggedIn: false,
        };
        case PROFILE:
          return {
            ...state,
            userProfile: action.payload,
          };
        case TOGGLE_LOGOUT_POPUP:
            return {
              ...state,
              logoutPopup: action.payload
            }
    default:
      return state;
  }
}
