import { NOTIFICATION_ON, NOTIFICATION_OFF } from "../types";

const initialState = {
  status: false,
  message: null,
  type: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_ON:
      return {
        ...state,
        message: action.params.message,
        status: true,
        type: action.params.type,
      };
    case NOTIFICATION_OFF:
      return {
        ...state,
        message: "",
        status: false,
        type: null,
      };
    default:
      return state;
  }
}
