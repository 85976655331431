import { postApi,getApi } from "../../utils/services";
import { errorHandler } from "../../utils/errorHandlers";
import {
  LOGIN,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  LOGOUT,
  PROFILE,
  TOGGLE_LOGOUT_POPUP
} from "../types";
import { setTokens, removeTokens } from "../../utils/localStorage";
import { loaderOn, loaderOff } from "../actions/loaderAction";
import { notificationOn } from "../actions/notificationAction";

export const login = (body) => async (dispatch) => {
  dispatch(loaderOn());
  try {
    const { data } = await postApi("/login", body);
    const { success, data: response } = data;

    if (success) {
      dispatch({ type: LOGIN, payload: response });
      dispatch(loaderOff());
      setTokens({
        user: null,
        token: response.token,
        refreshToken: response.refreshToken,
      });
      return true;
    }

  }
   catch (error) {
    await errorHandler(error);
    dispatch(loaderOff());
    return false;
  }
};
export const forcedLogout = (id) => async (dispatch) => {
  const body = {
    id: "E-" + id 
  }
  dispatch(loaderOn());
  try {
    const { data } = await postApi("/force-logout", body);

      // dispatch({ type: LOGOUT, payload: removeTokens });
      dispatch(loaderOff());
      return true;

  }
   catch (error) {
    await errorHandler(error);
    dispatch(loaderOff());
    return false;
  }
};

export const refreshToken = (body) => async (dispatch) => {
  dispatch(loaderOn());
  try {
    const { data } = await postApi("/refresh-token", body);
    const { success, data: response } = data;
    setTokens({
      user: null,
      token: response.token,
      refreshToken: response.refreshToken,
    });
    if (success) {
      dispatch({ type: LOGIN, payload: response });
      dispatch(loaderOff());
      return true;
    }
  } catch (error) {
    dispatch(loaderOff());
    return false;
  }
};

export const resetPassword = state => async dispatch => {
  dispatch(loaderOn());
  try {
    console.log(state);
    const { data } = await postApi("/resetPassword", state);

    const { success } = data;
   
    if (success) {
      dispatch({ type: RESET_PASSWORD });
      dispatch(loaderOff());
      return true;
    }
  } catch (error) {
    await errorHandler(error);
    dispatch(loaderOff());
    return false;
  }
};

export const forgotPassword = body => async dispatch => {
  dispatch(loaderOn());
  try {
    const { data } = await postApi("/forget-password", body);
    const { success, data: response } = data;
    if (success) {
      dispatch(loaderOff())
      return true;
    }
  } catch (error) {
    await errorHandler(error);
    dispatch(loaderOff());
    return false;
  }
};

export const changePassword = state => async dispatch => {
  dispatch(loaderOn());
  try {
    const { data } = await postApi("/change-password", state);

    const { success, data: response } = data;

    if (success) {
      dispatch({ type: CHANGE_PASSWORD,payload: response });
      dispatch(loaderOff());
      dispatch(
        notificationOn({
          message: "Password Updated Successfully",
          type: "success",
        })
      );
      return true;
    }
  } catch (error) {
    await errorHandler(error);
    dispatch(loaderOff());
    return false;
  }
};

export const logout = () => async dispatch => {
  try {
    removeTokens({
      user: null,
      token: null,
      refreshToken: null,
    });

    dispatch({ type: LOGOUT, payload: removeTokens });
    return true;
  } catch (error) {
    await errorHandler(error);
    return false;
  }
};





export const profile = (params) => async (dispatch) => {
  dispatch(loaderOn());
  try {
    const { data } = await getApi(`/admin/profile`, "", params);
    const { success } = data;
    if (success) {
      dispatch({ type: PROFILE, payload: data.data });
      dispatch(loaderOff());
      return true;
    }
  } catch (error) {
    await errorHandler(error);
    dispatch(loaderOff());
    return false;
  }
};

export const toggleLogoutPopup = (visible) => {
  return {
    type: TOGGLE_LOGOUT_POPUP,
    payload: visible,
  };
};

