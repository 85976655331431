//AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const PROFILE = 'PROFILE';
export const TOGGLE_LOGOUT_POPUP = 'TOGGLE_LOGOUT_POPUP';



//LOADER
export const LOADER_ON = 'LOADER_ON';
export const LOADER_OFF = 'LOADER_OFF';

//NOTIFICATION
export const NOTIFICATION_ON = "NOTIFICATION_ON";
export const NOTIFICATION_OFF = "NOTIFICATION_OFF";

//SIDEBAR
export const SIDEBAR_SET = "sidebar/set";

//HEADER
export const HEADER_INORMATIVE_POPUP_OPEN_TOGGLE = "header/inormativePoupOpenToggle";

//EMPLOYEE
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const LIST_BRANCHES = "LIST_BRANCHES";
export const LIST_EMPLOYEE = "LIST_EMPLOYEE";
export const VIEW_EMPLOYEE = "VIEW_EMPLOYEE";
export const EDIT_EMPLOYEE= "EDIT_EMPLOYEE";
export const DELETE_EMPLOYEE= "DELETE_EMPLOYEE";
export const CHECK_EMPLOYEE ="CHECK_EMPLOYEE";

//DOCUMENT TYPE 
export const LIST_CERTIFICATE = "LIST_CERTIFICATE";
export const CREATE_CERTIFICATE = "CREATE_CERTIFICATE";
export const VIEW_CERTIFICATE = "VIEW_CERTIFICATE";
export const EDIT_CERTIFICATE = "EDIT_CERTIFICATE";
export const DELETE_CERTIFICATE = "DELETE_CERTIFICATE";

//PROCESS
export const LIST_PROCESS = "LIST_PROCESS";
export const CREATE_PROCESS = "CREATE_PROCESS";
export const VIEW_PROCESS = "VIEW_PROCESS";
export const EDIT_PROCESS = "EDIT_PROCESS";
export const DELETE_PROCESS = "DELETE_PROCESS";

//CUSTOMER
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const VIEW_CUSTOMER = "VIEW_CUSTOMER";
export const LIST_CUSTOMER = "LIST_CUSTOMER";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const CHECK_CUSTOMER = "CHECK_CUSTOMER";

// export const LIST_CORPORATE_CUSTOMER = "LIST_CORPORATE_CUSTOMER";
// export const LIST_INDIVIDUAL_CUSTOMER = "LIST_INDIVIDUAL_CUSTOMER";


export const LIST_CORPORATE_CUSTOMER = "LIST_CORPORATE_CUSTOMER";
export const LIST_INDIVIDUAL_CUSTOMER = "LIST_INDIVIDUAL_CUSTOMER";

//SERVICE
export const ADD_PROCESS_LIST = "ADD_PROCESS_LIST";
export const CREATE_CANDIDATE = "CREATE_CANDIDATE";
export const CREATE_STARTPROCESS="CREATE_STARTPROCESS"
export const VALIDATE_RECEIPT = "VALIDATE_RECEIPT";
export const ALL_CUSTOMER_NAME = "ALL_CUSTOMER_NAME";
export const ADD_DOC_LIST = "ADD_DOC_LIST";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const CHECKED_ROWS = "CHECKED_ROWS"
export const LIST_PROCESSTABLE = "LIST_PROCESSTABLE"
export const LIST_PENDINGSERVICE = "LIST_PENDINGSERVICE"
export const VIEW_PENDINGSERVICE = "VIEW_PENDINGSERVICE"

export const LIST_ACTIVESERVICE = "LIST_ACTIVESERVICE"
export const LIST_COMPLETEDSERVICE = "LIST_COMPLETEDSERVICE"
export const LIST_ONGOINGSERVICE = "LIST_ONGOINGSERVICE"
export const LIST_DELIVEREDSERVICE = "LIST_DELIVEREDSERVICE"
export const LIST_OUTFORDELIVERY = "LIST_OUTFORDELIVERY"
export const VIEW_ACTIVESERVICE = "VIEW_ACTIVESERVICE"
export const VIEW_COMPLETEDSERVICE="VIEW_COMPLETEDSERVICE"
export const LIST_COMPLETEDSERVICEPROCESS="LIST_COMPLETEDSERVICEPROCESS"
export const VIEW_ACTIVEPROCESSES="VIEW_ACTIVEPROCESSES"
export const VIEW_ONGOINGPROCESSES="VIEW_ONGOINGPROCESSES"
export const VIEW_DELIVEREDPROCESSES="VIEW_DELIVEREDPROCESSES"
export const ACTIVE_KEY="ACTIVE_KEY"
export const DELETE_PENDING_SERVICE="DELETE_PENDING_SERVICE"


// export const VIEW_OUTFORDELIVERYPROCESS="VIEW_OUTFORDELIVERYPROCESS"
export const EDIT_PENDINGSERVICE="EDIT_PENDINGSERVICE"
export const VIEW_OUTFORDELIVERYPROCESS = "VIEW_OUTFORDELIVERYPROCESS"
export const START_PROCESS_BTN_CLICK_SELECTEDROWS = "START_PROCESS_BTN_CLICK_SELECTEDROWS"
export const UPDATE_DELIVERY = "UPDATE_DELIVERY"
export const UPDATE_DELIVERY_BTN_CLICK_SELECTEDROWS = "UPDATE_DELIVERY_BTN_CLICK_SELECTEDROWS"



//Agency
export const CREATE_AGENCY ="CREATE_AGENCY"
export const VIEW_AGENCY ="VIEW_AGENCY"
export const LIST_AGENCY="LIST_AGENCY"
export const  EDIT_AGENCY=" EDIT_AGENCY"



//Branch
export const CREATE_BRANCH ="CREATE_BRANCH"
export const LIST_BRANCH ="LIST_BRANCH"
export const VIEW_BRANCH ="VIEW_BRANCH"
export const EDIT_BRANCH ="EDIT_BRANCH"
//Transit
export const LIST_INHAND ="LIST_INHAND"
export const VIEW_INHAND ="VIEW_INHAND"
export const LIST_OUTBOUND ="LIST_OUTBOUND"
export const VIEW_OUTBOUND ="VIEW_OUTBOUND"
export const LIST_INBOUND ="LIST_INBOUND"
export const LIST_RECEIVED ="LIST_RECEIVED"
export const  VIEW_RECEIVED =" VIEW_RECEIVED"
export const LIST_SENT ="LIST_SENT"
export const  VIEW_SENT =" VIEW_SENT"
export const INHAND_TRANSFER ="INHAND_TRANSFER"
export const VIEW_INBOUND ="VIEW_INBOUND"
export const RECEIVE_DOCUMENT ="RECEIVE_DOCUMENT"

//Dashboard
export const VIEW_DASHBOARD ="VIEW_DASHBOARD"
export const DETAILEDVIEW_DASHBOARD ="DETAILEDVIEW_DASHBOARD"

//RECEIVABLE
export const CREATE_RECEIVABLE ="CREATE_RECEIVABLE"
export const VIEW_RECEIVABLE ="VIEW_RECEIVABLE"
export const LIST_RECEIVABLE="LIST_RECEIVABLE"
export const  EDIT_RECEIVABLE=" EDIT_RECEIVABLE"