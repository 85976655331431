import { combineReducers } from "redux";
import authReducer from "./authReducer";
import loaderReducer from "./loaderReducer";
import notificationReducer from "./notificationReducer";
import sidebarReducer from "./sidebarReducer";
import headerReducer from "./headerReducer";
import customerReducer from "./customerReducer";
import processReducer from "./processReducer";
import documentTypeReducer from "./documentTypeReducer";
import employeeReducer from "./employeeReducer";
import serviceReducer from "./serviceReducer";
import agencyReducer from "./agencyReducer";
import branchReducer from "./branchReducer";
import transitReducer from "./transitReducer";
import dashboardReducer from "./dashboardReducer";
import receivableReducer from "./receivableReducer";





export default combineReducers({
  auth: authReducer,
  loaderReducer,
  notificationReducer,
  sidebarReducer,
  headerReducer,
  customerReducer,
  documentTypeReducer,
  employeeReducer,
  processReducer,
  serviceReducer,
  agencyReducer,
  branchReducer,
  transitReducer,
  dashboardReducer,
  receivableReducer
});
