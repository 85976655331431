import APP_IMAGE_PATHS from "../constants/imagePaths";

const GLOBAL_CONSTANTS = {
  DATE_FORMAT: {
    USER_DROPDOWN: " dd MMM yyyy hh:mm ",
    PROFILE_PAGE: "dd/MM/yyyy hh:mm a",
  },
  MENU: [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: APP_IMAGE_PATHS.ICON_DASHBOARD,
      type: "Dashboard",
    },
    {
      name: "Service",
      to: "/service",
      icon: APP_IMAGE_PATHS.ICON_SERVICE,
      type: "Service",
    },
    {
      name: "Transit",
      to: "/transit",
      icon: APP_IMAGE_PATHS.ICON_TRANSIT,
      type: "Transit",
    },
    {
      name: "Accounts",
      icon: APP_IMAGE_PATHS.ICON_WALLET,
      type: "Accounts",
      items: [
        {
          name: "Expense",
          to: "/expense",
        },
        {
          name: "Receivables",
          to: "/receivable"
        },
        {
          name: "Chart of Accounts",
          to: "/chart-of-accounts",
        }
      ]
    },
    {
      name: "Reports",
      icon: APP_IMAGE_PATHS.ICON_REPORT,
      type: "Reports",
      items: [
        {
          name: "Service Report",
          to: "/reports",
        },
        {
          name: "SOA Report",
          to: "/soareport",
        },
        {
          name: "CNR Report",
          to: "/cnrreport",
        },
        {
          name: "Non Delivered Report",
          to: "/nondeliveredreport",
        },
        {
          name: "Delivered Report",
          to: "/deliveredreport",
        },
        {
          name: "Outstanding Report",
          to: "/outstandingreport",
        },
      ],
    },
   
    {
      name: "Customer",
      to: "/customer",
      icon: APP_IMAGE_PATHS.ICON_CUSTOMER,
      type: "Customer",
    },

    {
      name: "Employee",
      to: "/employee",
      icon: APP_IMAGE_PATHS.ICON_EMPLOYEE,
      type: "Customer",
    },

    {
      name: "Document Type",
      to: "/documentType",
      icon: APP_IMAGE_PATHS.ICON_DOCUMENT,
      type: "Document Type",
    },
    {
      name: "Process",
      to: "/process",
      icon: APP_IMAGE_PATHS.ICON_PROCESS,
      type: "Process",
    },
    {
      name: "Courier Agency",
      to: "/agency",
      icon: APP_IMAGE_PATHS.ICON_COURIER,
      type: "Agency",
    },
    {
      name: "Branch",
      to: "/branch",
      icon: APP_IMAGE_PATHS.ICON_BRANCH,
      type: "Branch",
    },
  
  ],
};

export default GLOBAL_CONSTANTS;
