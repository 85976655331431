import { CREATE_BRANCH ,LIST_BRANCH,VIEW_BRANCH,EDIT_BRANCH, } from "../types";

const initialState = {
  isLoaded: false,
  list:[],
  listisLoaded: false,
  totalCount:0,
  branch:{},

};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_BRANCH:
      return {
        ...state,
        isLoaded: false,
      };
      case LIST_BRANCH:
        return {
      ...state,
      list: action.payload.branches,
      totalCount: action.payload.count,
      isLoaded: true,
    };
    case VIEW_BRANCH:
      return {
        ...state,
        branch: action.payload,
      };
      case EDIT_BRANCH:
        console.log("edittt", action.payload);
        return {
          ...state,
          branch: {},
          isLoaded: false,
          allbranchLoaded: false,
        };

    default:
      return state;
  }

}
