import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, useLocation } from "react-router-dom"
import Loader from '../../components/Loader'
import { profile } from '../../redux/actions/authActions'
const PrivateRoute = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch()
    const {
      isLoggedIn,
      userProfile,
      // loginLoader
    } = useSelector((state) => state.auth)
  
    useEffect(() => {
      if(isLoggedIn && !userProfile ) {
        dispatch(profile());
      }
    }, [userProfile])
  
    if (!isLoggedIn) {
      return <Navigate to="/login" state={{ from: location }} replace/>
    } else if(!userProfile) {
      return <Loader />
    } else {
      return children
    }
  };

export default PrivateRoute;