import {
  LIST_EMPLOYEE,
  CREATE_EMPLOYEE,
  LIST_BRANCHES ,
  VIEW_EMPLOYEE,
  EDIT_EMPLOYEE,
  FILE_UPLOAD,
  DELETE_EMPLOYEE,
  CHECK_EMPLOYEE,
} from "../types";

const initialState = {
  listisLoaded: false,
  isLoaded: false,
  employees: [],
  lists:[],
  employee: {},
  totalCount: 0,
  checks: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_EMPLOYEE:
      console.log("action.payload.employee:3#### ", action.payload.employee);

      return {
        ...state,
        employees: action.payload.employee,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case VIEW_EMPLOYEE:
      return {
        ...state,
        employee: action.payload,
      };
    case CREATE_EMPLOYEE:
      return {
        ...state,
        isLoaded: false,
      };
      case LIST_BRANCHES:
        return {
          ...state,
          lists: action.payload.Branch,
          totalCount: action.payload.count,
          isLoaded: true,
        };
    case EDIT_EMPLOYEE:
      console.log("dfd44555", action.payload);
      return {
        ...state,
        employee: {},
        isLoaded: false,
        allemployeeLoaded: false,
      };
    case DELETE_EMPLOYEE:
      return {
        ...state,
        isLoaded: false,
        allEmployeesIsLoaded: false,
      };
    case CHECK_EMPLOYEE:
      return {
        ...state,
        isLoaded: false,
        checks: action.payload.exists,
      };
    default:
      return state;
  }
}
