const APP_IMAGE_PATHS = {
  LOGO: "/images/express-logo-mini.png",
  EMAIL_ICON: "/images/icon-email.svg",
  USER_ICON: "/images/icon-user.svg",
  LOCK_ICON: "/images/icon-lock.svg",
  PASSWORD_EYE_ICON: "/images/icon-eye.svg",
  PASSWORD_EYE_OFF_ICON: "/images/icon-eye-off.svg",
  AVATAR: "/images/avatar.jpg",
  NOTIFICATION: "/images/icon-notifications-bell.svg",
  HAMBURGER: "/images/icon-hamburger.svg",
  LOGO_MINI: "/images/Express-mini-logo-sidebar.svg",
  LOGO_SIDEBAR: "/images/Express-logo-sidebar.png",
  ICON_DASHBOARD: "/images/icon-dashboard.svg",
  ICON_TABLE_EDIT: "/images/icon-edit.svg",
  ICON_TABLE_DELETE: "/images/icon-delete.svg",
  ICON_EMPLOYEE: "/images/icon-employee.svg",
  ICON_DOCUMENT: "/images/icon-documentType.svg",
  ICON_REPORT: "/images/icon-report.svg",
  ICON_COURIER: "/images/icon-courier.svg",
  ICON_SEARCH: "/images/icon-search.svg",
  ICON_CUSTOMER: "/images/icon-customer.svg",
  ICON_LOCATION: "/images/icon-metro-location.svg",
  ICON_SERVICE: "/images/icon-service.svg",
  ICON_TRANSIT: "/images/Icon-transit.svg",
  ICON_CLOSE: "/images/icon-close.svg",
  ICON_EYE_VIEW: "/images/view-eye-icon.svg",
  ICON_DOWNLOAD: "/images/Icon feather-download.svg",
  ICON_PROCESS: "/images/icon-process.svg",
  ICON_CALENDAR: "/images/calendar-icon.png",
  ICON_BRANCH: "/images/Icon-branch.svg",
  ICON_DELETE: "/images/icon-deletemodal.svg",
  ICON_WALLET: "/images/ionic-md-wallet.svg"

};
export default APP_IMAGE_PATHS;
