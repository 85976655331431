import axios from "../axios.js";
import { getAccessToken } from "./localStorage";
export const getApi = async (url, id="", params = {}) =>

  axios.get(url + id, { params });

export const putApi = async (url, body = {}) => axios.put(url, body);

export const postApi = async (url, body = {}) => {

  if (getAccessToken) {
    return axios.post(url, body, {
      headers: {
        Authorization: getAccessToken`Bearer ${getAccessToken || null}`,
      },
    });
  }
};

export const deleteApi = async (url, id) => axios.delete(url + "/" + id);

export const deleteWithParamApi = async (url, params = {}) =>
  axios.delete(url, { params });
