const setTokens = ({ user, token, refreshToken }) => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", JSON.stringify(token));
  localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
};

const removeTokens = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
};
const getAccessToken = () => localStorage.getItem("token");

const getUser = () => localStorage.getItem("user");

const setUser = (user) => localStorage.setItem("user", JSON.stringify(user));

const getRefreshToken = () => localStorage.getItem("refreshToken");

const isAuthenticated = () => {
  return getAccessToken() ? true : false;
};

module.exports = {
  setTokens,
  removeTokens,
  getAccessToken,
  getRefreshToken,
  getUser,
  setUser,
  isAuthenticated,
};
