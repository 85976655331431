import React from "react"
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from "react-router-dom"

const AuthRoute = ({ children }) => {
  const location = useLocation();
  const from = '/service' //location.state?.from?.pathname || '/dashboard'
  const { isLoggedIn } = useSelector((state) => state.auth)
  if (isLoggedIn) {
    return <Navigate to={from} replace/>
  } else {
    return children
  }
};

export default AuthRoute;