import { LIST_PROCESS,CREATE_PROCESS ,VIEW_PROCESS,EDIT_PROCESS,DELETE_PROCESS} from "../types";

const initialState = {
  list: [],
  listisLoaded: false,
  count: 0,
  processes: {},
  isLoaded: false,
};


export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_PROCESS:
      
      return {
        ...state,
        list: action.payload.process,
        totalCount: action.payload.count,
        isLoaded: true,
      };
     
      case VIEW_PROCESS:
        return {
          ...state,
          processes: action.payload,
        };
      case CREATE_PROCESS:
        return {
          ...state,
          isLoaded: false,
        };
        case EDIT_PROCESS:
          console.log("dfd44555",action.payload)
          return {
            ...state,
            processes: {},
            isLoaded: false,
            allProcessesIsLoaded: false,
          };
          case DELETE_PROCESS:
        return {
          ...state,
          isLoaded: false,
          allProcessesIsLoaded: false,
        };
        default:
          return state; 
  }
};



