import {
  ADD_PROCESS_LIST,
  CREATE_CANDIDATE,
  VALIDATE_RECEIPT,
  ALL_CUSTOMER_NAME,
  ADD_DOC_LIST,
  CREATE_SERVICE,
  LIST_PENDINGSERVICE,
  CHECKED_ROWS,
  LIST_ACTIVESERVICE,
  VIEW_ACTIVESERVICE,
  LIST_COMPLETEDSERVICEPROCESS,
  EDIT_PENDINGSERVICE,
  VIEW_PENDINGSERVICE,
  LIST_COMPLETEDSERVICE,
  VIEW_COMPLETEDSERVICE,
  LIST_PROCESSTABLE,
  CREATE_STARTPROCESS,
  VIEW_ACTIVEPROCESSES,
  LIST_ONGOINGSERVICE,
  LIST_DELIVEREDSERVICE,
  LIST_OUTFORDELIVERY,
  VIEW_ONGOINGPROCESSES,
  VIEW_DELIVEREDPROCESSES,
  VIEW_OUTFORDELIVERYPROCESS,
  START_PROCESS_BTN_CLICK_SELECTEDROWS,
  ACTIVE_KEY,
  DELETE_PENDING_SERVICE
} from "../types";

const initialState = {
  serviceProcess: [],
  isLoaded: false,
  allCustomerIsLoaded: false,
  allCustomerName: [],
  serviceDoc: [],
  pendingserviceProcess: [],
  candidates: [],
  processes: [],
  amounts: [],
  orders: [],
  activeserviceProcess: [],
  completedserviceProcess: [],
  completedserviceviewProcess: [],
  ongoingServiceProcess: [],
  deliveredServiceProcess: [],
  outForDeliveryServiceProcess: [],
  processesView: [],
  checkedRows: [],

  services: [],
  isServiceLoaded: false,
  isServiceLoadeds: false,
  processTable: [],
  startProcessBtnClickSelectedRows: [],
  updateDeliveryBtnClickSelectedRows:[],
  activeKey: "pending"
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PROCESS_LIST:
      return {
        ...state,
        serviceProcess: action.payload.Process,
        isLoaded: true,
      };
    case CREATE_CANDIDATE:
      return {
        ...state,
        isLoaded: false,
      };
    case VALIDATE_RECEIPT:
      return {
        ...state,
        receiptNumber: action.payload,
        isLoaded: false,
      };
    case ALL_CUSTOMER_NAME:
      return {
        ...state,
        allCustomerName: action.payload.customers,
        allCustomerIsLoaded: false,
      };
    case ADD_DOC_LIST:
      return {
        ...state,
        serviceDoc: action.payload.Certificate,
        isLoaded: true,
      };
    case CREATE_SERVICE:
      return {
        ...state,
        isServiceLoaded: true,
      };
    case LIST_PENDINGSERVICE:
      return {
        ...state,
        pendingserviceProcess: action.payload.pendingService,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case VIEW_PENDINGSERVICE:
      return {
        ...state,
        candidates: action.payload.candidates,
        processes: action.payload.processes,
        amounts: action.payload.amounts,
        orders: action.payload.orders,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case CHECKED_ROWS:
      return {
        ...state,
        checkedRows: action.payload.checkedRows,
      };

    case LIST_ACTIVESERVICE:
      console.log("aqwsedrftgyhj", action.payload.Listactive);
      return {
        ...state,
        activeserviceProcess: action.payload.Listactive,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case VIEW_ACTIVESERVICE:
      return {
        ...state,
        candidates: action.payload.candidates,
        processes: action.payload.processes,
        amounts: action.payload.amounts,
        orders: action.payload.orders,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case LIST_COMPLETEDSERVICE:
      return {
        ...state,
        completedserviceProcess: action.payload.completeService,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case VIEW_COMPLETEDSERVICE:
      return {
        ...state,
        candidates: action.payload.candidates,
        amounts: action.payload.amounts,
        orders: action.payload.orders,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case LIST_COMPLETEDSERVICEPROCESS:
      return {
        ...state,
        completedserviceviewProcess: action.payload,

        totalCount: action.payload.count,
        isLoaded: true,
      };
    case EDIT_PENDINGSERVICE:
      console.log("dfd44555", action.payload);
      return {
        ...state,
        services: action.payload.services,
        candidatess: action.payload.candidates,
        processess: action.payload.processes,
        isLoaded: false,
        allServicesIsLoaded: false,
      };

    case VIEW_ONGOINGPROCESSES:
      return {
        ...state,
        candidates: action.payload.candidates,
        amounts: action.payload.amounts,
        orders: action.payload.orders,
        totalCount: action.payload.count,
        isLoaded: true,
      };

    case VIEW_DELIVEREDPROCESSES:
      return {
        ...state,
        candidates: action.payload.candidates,
        amounts: action.payload.amounts,
        orders: action.payload.orders,
        totalCount: action.payload.count,
        isLoaded: true,
      };

    case VIEW_OUTFORDELIVERYPROCESS:
      return {
        ...state,
        candidates: action.payload.candidates,
        amounts: action.payload.amounts,
        orders: action.payload.orders,
        totalCount: action.payload.count,
        isLoaded: true,
      };


    case VIEW_DELIVEREDPROCESSES:
      return {
        ...state,
        candidates: action.payload.candidates,
        amounts: action.payload.amounts,
        orders: action.payload.orders,
        totalCount: action.payload.count,
        isLoaded: true,
      };

    case LIST_COMPLETEDSERVICE:
      return {
        ...state,
        completedserviceviewProcess: action.payload,

        totalCount: action.payload.count,
        isLoaded: true,
      };
    case EDIT_PENDINGSERVICE:
      console.log("dfd44555", action.payload);
      return {
        ...state,
        services: action.payload.services,
        candidatess: action.payload.candidates,
        processess: action.payload.processes,
        isLoaded: false,
        allServicesIsLoaded: false,
      };

    case LIST_PROCESSTABLE:
      console.log("peocesses in reducer ",action.payload.startProcess)
      return {
        ...state,
        processTable: action.payload.startProcess,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case CREATE_STARTPROCESS:
      return {
        ...state,
        isServiceLoadeds: true,
      }; 
   

    case VIEW_ACTIVEPROCESSES:
     

      return {
        ...state,
        processesView: action.payload.Process,
        isLoaded: true,
      };

    case LIST_ONGOINGSERVICE:
      return {
        ...state,
        ongoingServiceProcess: action.payload.ongoingServices,
        totalCount: action.payload.count,
        isLoaded: true,
      };

    case LIST_DELIVEREDSERVICE:
      return {
        ...state,
        deliveredServiceProcess: action.payload.deliveredService,
        totalCount: action.payload.count,
        isLoaded: true,
      };

    case LIST_OUTFORDELIVERY:
      console.log("actionLLLL", action.payload);
      return {
        ...state,
        outForDeliveryServiceProcess: action.payload.outForDelService,
        totalCount: action.payload.count,
        isLoaded: true,
      };

    case VIEW_OUTFORDELIVERYPROCESS:
      return {
        ...state,
        candidates: action.payload.candidates,
        amounts: action.payload.amounts,
        orders: action.payload.orders,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    // case LIST_OUTFORDELIVERY:
    //   console.log("actionLLLL", action.payload);
    //   return {
    //     ...state,
    //     outForDeliveryServiceProcess: action.payload.outForDelService,
    //     totalCount: action.payload.count,
    //     isLoaded: true,
    //   };

    case START_PROCESS_BTN_CLICK_SELECTEDROWS:
      return {
        ...state,
        startProcessBtnClickSelectedRows:
          action.payload.startProcessBtnClickSelectedRows,
        isLoaded: true,
      };
    case ACTIVE_KEY:
      return {
        ...state,
        activeKey: action.payload.activeKey,
        isLoaded: true,
      }
      case DELETE_PENDING_SERVICE:
        return {
          ...state,
          isLoaded: false,
          // allCustomerIsLoaded: false,
        };
    default:
      return state;
  }
}
