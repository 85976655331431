import { CREATE_RECEIVABLE, VIEW_RECEIVABLE, LIST_RECEIVABLE,EDIT_RECEIVABLE } from "../types";
const initialState = {
  isLoaded: false,
  receivable: {},
  receivableList: [],
  totalCount: 0,
  receivableProcess:[]

};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_RECEIVABLE:
      console.log('action.payload :>> ', action.payload);
      return {
        ...state,
        receivableList: action.payload.receivableList,
        totalCount: action.payload.count,
        isLoaded: true,
      };
    case VIEW_RECEIVABLE:
      return {
        ...state,
        receivable: action.payload.receivable,

      };
    case CREATE_RECEIVABLE:
      return {
        ...state,
        isLoaded: false,
      };
      case EDIT_RECEIVABLE:
        return {
          ...state,
          receivable: {},
          isLoaded: false,
          allemployeeLoaded: false,
        };

    default:
      return state;
  }
}
