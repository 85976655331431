import {CREATE_CUSTOMER,
    VIEW_CUSTOMER,LIST_CUSTOMER,EDIT_CUSTOMER,DELETE_CUSTOMER,CHECK_CUSTOMER
 } from "../types";

const initialState = {
    list:[],
    customer: {},
    isLoaded: false,
    count: 0,
    customers:[],
    check:{}
 
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_CUSTOMER:
      return {
        ...state,
        isLoaded: false,
        customers: action.payload.customers,
      };
      case VIEW_CUSTOMER:
        console.log('action.payload: ', action.payload);
        return {
          ...state,
          customer: action.payload,
        };
        case LIST_CUSTOMER:
          console.log("action.payload.customers",action.payload.customers);
          return {
            
            ...state,
            list: action.payload.customers,
            totalCount: action.payload.count,
            isLoaded: true,
          };
          case EDIT_CUSTOMER:
            console.log("dfd44555",action.payload)
            return {
              ...state,
              customers: {},
              isLoaded: false,
              allCustomersIsLoaded: false,
            };
            case DELETE_CUSTOMER:
              return {
                ...state,
                isLoaded: false,
                allCustomersIsLoaded: false,
              };
              case CHECK_CUSTOMER:
                return {
                  ...state,
                  isLoaded: false,
                  check: action.payload.exists,
                };
    default:
      return state;
  }
}

