import { SIDEBAR_SET } from "../types";
import GLOBAL_CONSTANTS from "../../constants/index";

// Initial State
const initialState = {
    sidebarShow: true,
    menuData: GLOBAL_CONSTANTS.MENU,
  };
  
  // Reducer
  export const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
      case SIDEBAR_SET:

        return {
          ...state,
          sidebarShow: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default sidebarReducer;
  