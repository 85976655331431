import { NotificationManager } from "react-notifications";
import format from "date-fns/format";

export const contentFooterfix = () => {
    const contentFooter = document.querySelector(
      ".content-page-footer.fixed-footer"
    );
    const contentBody = document.querySelector(".default-layout-body");
    if (contentFooter) {
      const heightFooter = contentFooter.offsetHeight;
      if (contentBody) {
        // contentBody.style.paddingBottom = heightFooter + "px";
        if (!contentFooter.classList.contains("grey-footer")) {
          // contentBody.classList.add("bg-light");
        } else {
          // contentBody.classList.remove("bg-light");
        }
        
      }
    } else {
      if (contentBody) {
        // contentBody.style.paddingBottom = "0px";
        // contentBody.classList.remove("bg-light");
      }
    }
  };
  
  window.contentFooterfix = () => {
    setTimeout(() => {
      contentFooterfix();
    }, 1000);
  };
 
  export const notification = ({ type, message }) => {
    switch (type) {
      case "info":
        NotificationManager.info(message);
        break;
      case "success":
        NotificationManager.success(message);
        break;
      case "warning":
        NotificationManager.warning(message);
        break;
      case "error":
        NotificationManager.error(message);
        break;
    }
  };

  export const formatDate = (date, FORMAT = "MM-dd-yyyy") => {
    try {
      return date ? format(new Date(date), "dd-MMMM-yyyy") : "";
    } catch (err) {
      return "";
    }
  };
  export const formatDateTime = (date, FORMAT = "MM-dd-yyyy") => {
    try {
      return date ? format(new Date(date), "dd-MMMM-yyyy hh:mm a") : "";
    } catch (err) {
      return "";
    }
  };
  export const formatSortQuery = (sortObj) => {
    for (let key in sortObj) {
      return { field: key, order: sortObj[key] };
    }
    return { field: '', order: '' };
  };
